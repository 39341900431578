.wrapper {
  font-family: 'Montserrat';
}
.button {
    text-align: center;
    font-size: 18px;
    padding: 19px 60px;
    border-radius: 16px;
    background-image: linear-gradient(to bottom, #6D49FF, #281a60);
    color: #fff;
    cursor: pointer;
  }
  
  .iframe {
    width: 100vw;
    height: 100vh;
  }
  
  .connectWallet {
    width: 100vw;
    height: 100vh;
    position: absolute;
    background: url() no-repeat center center fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  
  .gameMain span {
    padding: 20px;
    background: #FFF;
    margin-top: 20px;
    border-radius: 10px;
    border: 2px solid #f97006;
  }
  
  .gameMain span a {
    font-weight: bold;
    color: #f97006;
    text-decoration: underline;
  }
  
  .btnConnect {
    text-align: center;
    font-size: 18px;
    padding: 19px 60px;
    border-radius: 16px;
    background-image: -webkit-gradient(linear,left top,left bottom,from(#fc9807),to(#f85f03));
    background-image: -webkit-linear-gradient(top,#fc9807,#f85f03);
    background-image: -moz-linear-gradient(top,#fc9807,#f85f03);
    background-image: linear-gradient(
  180deg,#fc9807,#f85f03);
    color: #fff;
  }
  
  .container {
    width: 100%;
    max-width: 980px;
    margin: auto;
    display: flex;
    justify-content: center;
  }
  
  .pageBuy {
    background: #000000;
    padding: 37px 0;
    background-size: cover;
    background-position: center center;
    min-height: 100vh;
    color: #FFF;
  }
  
  
  .header .img {
    max-width: 285px;
    width: 100%;
    margin: 43px 0;
  }
  
  
  .contentInfo {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  
  .info {
    display: flex;
    flex-direction: column;
  }

  .info a img {
    display: table;
    margin: 40px auto;
    margin-bottom: 40px !important;
  }
  
  .info .img {
    box-shadow: rgb(0 0 0 / 15%) 6.95px 6.95px 5.6px;
    border-radius: 32px;
    margin-bottom: 10px;
    max-width: 380px;
  }
  
  .info .name {
    font-size: 24px;
    color: #6D49FF;
    text-align: center;
    width: 100%;
    font-weight: 700;
    margin-top: 35px;
  }
  
  .wallet {
    max-width: 460px;
    width: 100%;
    margin-top: 60px;
  }

  .title span {
    font-size: 26px;
    text-transform: uppercase;
    color: #0a9bb7;
  }
  
  .wallet .title {
    font-size: 48px;
    font-weight: 800;
    margin: 32px 0 16px;
  }
  
  .wallet .description {
    font-size: 24px;
    font-weight: 620;
    margin: 32px 0 16px;
  }
  
  .wallet .button {
    display: inline-block;
    margin: 40px 0;
  }
  
  .wallet .desconectedWallet {
    color: #d04848;
    font: 18px;
    font-weight: 600;
    display: block;
  }
  
  .wallet .desconectedWallet img {
    margin-right: 15px;
  }
  
  .wallet .explain {
    font-size: 16px;
    font-weight: 700;
    line-height: 1.5;
    letter-spacing: normal;
    color: #FFFFFF;
  }
  
  .wallet .link {
    color: #6D49FF;
    margin-left: 5px;
    text-decoration: underline;
  }
  
  .formByNft  {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .formByNft input {
    padding: 9px 132px 11px 22px;
    max-width: 220px;
    height: 60px;
    border-radius: 16px;
    border: solid 2px #6D49FF;
    background-color: #281a60;
    color: #fff;
    font-size: 16px;
  }
  
  .formByNft .button {
    max-width: 220px;
    width: 100%;
  }
  
  .formByNft input::placeholder {
    color: #fff;
  }
  
  .desktop {
    display: inline-block !important;
  }
  
  .mobile {
    display: none !important;
  }
  
  @media (max-width: 1000px) {
    .info {
      margin: 0 !important;
    }
    a .img {
      width: 100% !important;
    }
    .img {
      display: none;
    }
    .wallet {
      margin-top: 0;
    }
    .name {
      display: none;
    }
    .hidee {
      display: none !important;
    }
  
    .mobile {
      display: inline-block !important;
    }
  
    .info .img {
      width: 180px;
    }
  
    .contentInfo {
      flex-direction: column;
    }
  
    .pageBuy {
      width: 100%;
      overflow-x: hidden;
    }
  
    .wallet {
      padding: 0 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  
    .wallet .title {
      font-size: 40px;
      margin-top: 10px;
    }
  
    .wallet .description {
      font-size: 14px;
      text-align: center;
      padding: 0 5px;
      margin: 0;
    }
  
    .info {
      margin: 43px 0 50px;
      align-items: center;
    }
  
    .formByNft {
      flex-direction: column;
    }
  
    .formByNft input {
      margin-top: 41px;
      max-width: 100%;
    }
  
    .formByNft .button {
      max-width: 100%;
      margin-top: 16px;
    }
  
    .wallet .explain {
      margin-top: 0;
      text-align: center;
    }
  }